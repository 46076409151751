:root {
	--primary-color: #060a48;
	--secondary-color: #eb5e55;
	--white: #e5e5e5;
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;800&display=swap");

.cursor {
	width: 50px;
	height: 50px;
	background-color: var(--secondary-color);
	border-radius: 100%;
	position: fixed;
	transform: translate(-50%, -50%);
	pointer-events: none;
	transition: all 150ms ease-in-out;
	transition-property: background-color, opacity, transform, mix-blend-mode;
	z-index: 9999;
	mix-blend-mode: color-dodge;
	opacity: 1;
}

.cursor--hidden {
	opacity: 0;
}

.cursor--link-hovered {
	transform: translate(-50%, -50%) scale(4);
}

.cursor--clicked {
	transform: translate(-50%, -50%) scale(0.8);
}

.initializer {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: max(140vw, 140vh);
	height: max(140vw, 140vh);
	z-index: 99900;
	transition: all 1s cubic-bezier(0.62, 0.02, 0.3, 0.89);
	overflow: hidden;
	border-radius: 50%;
}

.initializer.done {
	width: 0px;
	height: 0px;
}

.initializer .images {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.initializer .images img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.initializer .loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eb5e55;
	z-index: 10;
	opacity: 0.99;
}

.initializer .loader img {
	min-width: 20vw;
	max-width: 20vw;
}

* {
	cursor: none;
}
body {
	background-color: var(--primary-color);
	cursor: none;
}

html {
	overflow-x: hidden;
	cursor: none;
}

.root {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
	font-family: "Manrope";
	color: var(--white);
}

a {
	text-decoration: none;
	color: var(--white);
}
a:visited {
	color: none;
}

.header {
	position: fixed;
	top: 0;
	width: 100%;
	padding: 5vw;
	padding-top: 3vw;
	padding-bottom: 0px;
	display: flex;
	align-items: center;
	z-index: 999;
}

.header img {
	width: min(20%, 10vw);
}

.pre-wrap {
	white-space: pre-wrap;
}

.root {
	width: 100%;
}

.container {
	padding: 5vw;
	max-width: 90vw;
	padding-bottom: 88vh;
	position: relative;
	width: 100%;
}

.subtitle {
	margin-top: -11vw;
}

.scroll-down {
	margin-top: -100px;
}

h1 {
	font-size: min(13vw, 30vh);
	font-weight: 900;
	-webkit-text-stroke-width: min(2.5vw, 2.5vh);
	line-height: 85%;
}

h1.secondary {
	color: var(--secondary-color);
	-webkit-text-stroke-width: min(3vw, 3vh);
}

h2 {
	font-size: min(12vw, 30vh);
	font-weight: 900;
	-webkit-text-stroke-width: 10px;
	line-height: 80%;
}

h2.secondary {
	color: var(--secondary-color);
	-webkit-text-stroke-width: min(3vw, 3vh);
	-webkit-text-stroke-color: var(--secondary-color);
}

h2.primary {
	color: var(--primary-color);
	-webkit-text-stroke-width: min(3vw, 3vh);
}

.kodex {
	color: var(--secondary-color);
	-webkit-text-stroke-width: 5px;
	-webkit-text-stroke-color: var(--secondary-color);
	font-weight: 900;
	font-family: "Epilogue";
}

.outline {
	color: var(--primary-color);
	-webkit-text-stroke-width: 0px;
	-webkit-text-stroke-color: transparent;
	text-shadow: 1px 1px 0 var(--secondary-color), -1px -1px 0 var(--secondary-color), 1px -1px 0 var(--secondary-color),
		-1px 1px 0 var(--secondary-color), 1px 1px 0 var(--secondary-color);
}

.outline-secondary {
	color: var(--secondary-color);
	-webkit-text-stroke-width: 0px;
	-webkit-text-stroke-color: transparent;
	text-shadow: 1px 1px 0 var(--primary-color), -1px -1px 0 var(--primary-color), 1px -1px 0 var(--primary-color),
		-1px 1px 0 var(--primary-color), 1px 1px 0 var(--primary-color);
}

.kodex-sub {
	color: var(--white);
	font-size: 36px;
	-webkit-text-stroke-width: 3px;
	-webkit-text-stroke-color: var(--white);
	line-height: 50%;
}

.team {
	display: flex;
	align-items: flex-end;
	width: 100%;
	height: 70vh;
	flex-direction: row-reverse;
	position: relative;
	z-index: 0;
	margin-top: min(-15vw, -15vh);
}

.team-member {
	flex: 3;
	position: relative;
	height: 100%;
	transition: all 0.6s cubic-bezier(0.62, 0.02, 0.3, 0.89);
	bottom: 0;
	max-height: 100%;
	z-index: -3;
}

.team-member * {
	transition: all 0.6s cubic-bezier(0.62, 0.02, 0.3, 0.89);
}

.team-member .member-info {
	z-index: 900;
	position: absolute;
	top: 50%;
	left: 30%;
	transform: translate(-50%, -50%);
	opacity: 0;
}

.team-member .member-name {
	white-space: pre-wrap;
	font-size: 48px;
	line-height: 90%;
	margin: 0;
	z-index: 100;
}

.team-member img {
	position: absolute;
	left: -30%;
	top: 0;
	z-index: -100;
	width: 130%;
	height: 100%;
	object-fit: contain;
}

.team-member .gradient-image {
	z-index: 2;
}

.team-member:hover {
	flex: 4;
	z-index: 3;
}

.partner-logo-container {
	width: 100%;
	margin-top: -100px;
	margin-bottom: 200px;
}

.partner-logo {
	height: 80px;
	object-fit: contain;
	margin-right: 90px;
	filter: grayscale(100%) brightness(20);
}

.team-member:hover .gradient-image {
	opacity: 0;
}

.team-member:hover .member-info {
	opacity: 1;
}

.team .team-gradient {
	background: linear-gradient(0deg, rgba(6, 10, 72, 1) 25%, rgba(6, 10, 72, 0) 50%, rgba(6, 10, 72, 0) 100%);
	position: absolute;
	bottom: -10px;
	width: 120%;
	height: 100%;
	z-index: 5;
	left: -10%;
	pointer-events: none;
}

.work-boxer-desc h2 {
	font-size: 120px;
	font-weight: 900;
	-webkit-text-stroke-width: 6px;
	-webkit-text-stroke-color: var(--white);
	position: "absolute";
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.work-boxer-desc {
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 1));
	display: flex;
	align-items: center;
	width: 100%;
	position: absolute;
	top: 50%;
	bottom: 50%;
	left: 0px;
}

.work-boxer-desc .desc-text {
	margin-left: 10%;
	width: 40%;
	font-size: 24px;
}

.work-boxer-desc .desc-text h3 {
	font-size: 36px;
}

.work-boxer-desc .desc-text h5 {
	font-weight: 400;
}

.work-boxer {
	display: flex;
	width: 120vw;
	margin-left: -5vw;
}

.work-boxer img {
	height: 80vh;
	filter: brightness(0.5);
}

.contact {
	padding: 100px;
	display: block;
	background-color: var(--secondary-color);
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80vh;
	margin-top: 10vh;
}

.contact h3 {
	color: var(--primary-color);
	font-size: 36px;
}

.contact .contact-subtitle {
	font-size: 48px;
}

.contact .contact-mail {
	font-size: 36px;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: var(--primary-color);
}

.parallax-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 90vh;
	z-index: -1;
}

.parallax-bg img {
	opacity: 0.05;
	width: 25%;
}

.project-tech {
	height: 30px;
	margin-left: 20px;
	filter: grayscale(100%) brightness(10);
}

.eu-logos {
	display: flex;
	align-items: center;
	padding-top: 20;
	flex-direction: row;
}

.eu-logos img {
	height: 70px;
}

.primary-button {
	background-color: var(--secondary-color);
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 12px;
	padding-bottom: 12px;
	font-size: 24px;
	outline: none;
	border: none;
}

.blogpost-item {
	background-color: var(--secondary-color);
	padding: 20px;
	margin-bottom: 10px;
}

.blogpost-item * {
	color: var(--primary-color);
	white-space: pre-line;
}

.blogpost-item h2 {
	font-size: max(6vw, 5vh);
	-webkit-text-stroke-width: min(0.5vw, 0.5vh);
	-webkit-text-stroke-color: var(--primary-color);
	line-height: 100%;
	color: var(--primary-color);
	margin: 0px;
	margin-bottom: 10px;
}

.services {
	display: flex;
	flex-direction: column;

	h2 {
		margin-bottom: 12px;
		margin-top: -100px;
		transform-origin: -50% center;
		:first {
			margin-top: 0px;
		}
	}
}

@media only screen and (max-width: 768px) {
	.container {
		padding: 5vw;
		max-width: 90vw;
		padding-bottom: 27vh;
		overflow: hidden;
	}

	h1 {
		font-size: 28vw;
		font-weight: 900;
		-webkit-text-stroke-width: 14px;
		color: var(--white);
		word-break: break-all;
		line-height: 85%;
	}

	h1.secondary {
		color: var(--secondary-color);
		-webkit-text-stroke-width: 10px;
	}

	h2 {
		font-size: 18vw;
		font-weight: 900;
		-webkit-text-stroke-width: 9px;
		color: var(--white);
		line-height: 85%;
	}

	h2.secondary {
		color: var(--secondary-color);
		-webkit-text-stroke-width: 9px;
	}

	h2.primary {
		color: var(--primary-color);
		-webkit-text-stroke-width: 9px;
	}

	.kodex {
		color: var(--secondary-color);
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: var(--secondary-color);
		font-weight: 900;
		font-family: "Epilogue";
	}

	.outline {
		color: var(--primary-color);
		-webkit-text-stroke-width: 0px;
		-webkit-text-stroke-color: transparent;
		text-shadow: 1px 1px 0 var(--secondary-color), -1px -1px 0 var(--secondary-color), 1px -1px 0 var(--secondary-color),
			-1px 1px 0 var(--secondary-color), 1px 1px 0 var(--secondary-color);
	}

	.outline-secondary {
		color: var(--secondary-color);
		-webkit-text-stroke-width: 0px;
		-webkit-text-stroke-color: transparent;
		text-shadow: 1px 1px 0 var(--primary-color), -1px -1px 0 var(--primary-color), 1px -1px 0 var(--primary-color),
			-1px 1px 0 var(--primary-color), 1px 1px 0 var(--primary-color);
	}

	.kodex-sub {
		color: var(--white);
		font-size: 20px;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: var(--white);
		line-height: 100%;
	}

	.work-boxer-desc h2 {
		font-size: 40px;
		font-weight: 900;
		-webkit-text-stroke-width: 3px;
		-webkit-text-stroke-color: var(--white);
		height: fit-content;
	}

	.work-boxer-desc {
		filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 1));
		flex-direction: column;
		top: 30%;
		align-items: flex-start;
	}

	.work-boxer {
		display: flex;
		width: 120vw;
		margin-left: -5vw;
	}

	.work-boxer img {
		height: 80vh;
	}

	.work-boxer-desc .desc-text {
		margin-left: 0;
		width: 100%;
		font-size: 16px;
	}

	.contact {
		padding: 10px;
		display: block;
		background-color: var(--secondary-color);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: fit-content;
	}

	.contact .contact-title {
		font-size: 54px;
		-webkit-text-stroke-width: 7px;
		-webkit-text-stroke-color: var(--primary-color);
	}

	.contact h3 {
		color: var(--primary-color);
		font-size: 24px;
	}

	.contact .contact-subtitle {
		font-size: 48px;
	}

	.contact .contact-mail {
		font-size: 24px;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: var(--primary-color);
	}

	.parallax-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 90vh;
		z-index: -1;
	}

	.parallax-bg img {
		opacity: 0.1;
		width: 50%;
	}

	.subtitle {
		margin-top: -50px;
		margin-bottom: 20px;
	}

	.title {
		margin-top: 45vh;
		word-break: break-all;
	}

	.scroll-down {
		margin-top: 0;
	}

	.header {
		position: fixed;
		top: 0;
		width: 100%;
		padding-top: 30px;
		display: flex;
		align-items: center;
		z-index: 999;
		max-width: 90vw;
	}

	.header img {
		width: min(30%, 30vw);
	}

	.team {
		display: flex;
		align-items: flex-start;
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap-reverse;
		height: fit-content;
		position: relative;
	}

	.team .team-gradient {
		display: none;
	}

	.team .team-member {
		width: 100%;
		height: auto;
		flex: auto;
	}

	.team-member img {
		width: 100%;
		height: auto;
		left: 0;
		top: 0;
		transform: none;
		object-fit: contain;
	}

	.team-member .member-info {
		opacity: 1;
		top: 70%;
		left: 35%;
	}

	.team-member .normal-image {
		position: relative;
	}

	.team-member .gradient-image {
		position: absolute;
	}

	.project-tech {
		height: 25px;
		margin-left: 10px;
		filter: grayscale(100%) brightness(10);
	}

	.project-tech:first-of-type {
		margin-left: 0px;
	}

	.partner-logo-container {
		width: 100%;
		margin-top: -50px;
		margin-bottom: 200px;
	}

	.eu-logos {
		display: flex;
		align-items: flex-start;
		padding-top: 20;
		flex-direction: column;
	}

	.eu-logos img {
		height: auto;
		margin-top: 20px;
		width: 80%;
	}

	.blogpost h2 {
		margin-top: 10vh;
	}

	.services {
		display: flex;
		flex-direction: column;

		h2 {
			margin-bottom: 0px;
			margin-top: 0px;
			transform-origin: -150px center;
			font-size: 10vw;
			:first {
				margin-top: 0px;
			}
		}
	}
}

