.blogpost,
.blog-write {
	background-color: #111111 !important;
	cursor: auto !important;

	.title {
		margin-bottom: 5px;
		line-height: 100%;
	}

	.desc {
		margin-top: 0px;
	}

	* {
		color: #dddddd !important;
		cursor: auto !important;
	}

	pre * {
		font-family: monospace;
		width: 100%;
	}

	a {
		text-decoration: underline;
		cursor: pointer !important;
	}

	.editor * {
		color: black !important;
	}

	input {
		padding: 24px;
		background: #ffffff11;
		outline: none;
		margin-bottom: 10px;
		border: none;
	}

	h2 {
		font-size: max(6vw, 5vh);
		-webkit-text-stroke-width: max(0.4vw, 0.3vh);
	}

	h1,
	h2 {
		margin-bottom: 24px;
	}

	.desc {
		font-size: max(3vw, 3vh);
		margin-bottom: 0px;
	}

	p {
		font-size: max(1.3vw, 2.2vh);
	}

	pre {
		padding: 20px;
		background-color: #ffffff11;
		word-wrap: break-word;
		word-break: break-all;
		white-space: pre-line;
	}

	code {
		background-color: #ffffff11;
		font-family: monospace;
	}

	blockquote {
		position: relative;
		font-style: italic;
	}
	blockquote::before {
		content: "";
		width: 5px;
		height: 100%;
		background-color: #ffffff11;
		position: absolute;
		left: -12px;
	}

	img {
		object-fit: contain;
		max-width: 100%;
		height: auto !important;
	}

	.user,
	.user * {
		color: var(--secondary-color) !important;
	}
}

